import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useProjectsPg = () => {
  // base data
  const projectsPg = ref (null);
  const loading = ref (false);

  // methods
  const getProjectsPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchProjectsPg');
    loading.value = false;
    if (status != 200) return;
    projectsPg.value = data;
    return data;
  };

  const updateProjectsPg = async projectsPg => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updateProjectsPg', projectsPg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    projectsPg,
    loading,
    // methods
    getProjectsPg,
    updateProjectsPg,
  };
};

export default useProjectsPg;
