<template>
  <v-card>
    <ProjectsPgHeader></ProjectsPgHeader>
    <section class="form-full-width">
      <FormProjectsPg :loading="loading" :getProjectsPg="getProjectsPg" @beforeUpdate="updateProjectsPg"></FormProjectsPg>
    </section>
  </v-card>
</template>

<script>
import FormProjectsPg from '@/components/forms/FormProjectsPg.vue'
import ProjectsPgHeader from './ProjectsPgHeader.vue'
import useProjectsPg from '@/composables/useProjectsPg'

export default {
  components: {
    FormProjectsPg,
    ProjectsPgHeader,
  },
  setup() {
    // Composition API
    const {
      projectsPg,
      loading,
      // methods
      getProjectsPg,
      updateProjectsPg,
    } = useProjectsPg()

    return {
      projectsPg,
      loading,
      // methods
      getProjectsPg,
      updateProjectsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
