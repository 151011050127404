<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formProjectsPg" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="title_cover_page">Título de Portada</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_cover_page"
              v-model="projectsPg.title_cover_page"
              outlined
              dense
              placeholder="Título de la Portada"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="img_cover_page">Imagen de Portada</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_cover_page"
              v-model="file_cover_page"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <div class="img-cover section">
              <img draggable="false" :src="url || loadImage(projectsPg.img_cover_page)" :alt="projectsPg.text" />
              <h1 class="cover-title">{{ projectsPg.title_cover_page }}</h1>
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'
export default {
  components: {
    FormTemplate,
  },
  props: {
    getProjectsPg: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      file_cover_page: null,
      projectsPg: {},
    }
  },
  async created() {
    this.projectsPg = await this.getProjectsPg()
  },
  methods: {
    validateForm() {
      return this.$refs.formProjectsPg.validate()
    },
    reset() {
      this.$refs.formProjectsPg.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.projectsPg }

      if (this.file_cover_page) {
        DTO = {
          ...DTO,
          file_cover_page: this.file_cover_page,
        }
      }

      // // console.log('actualizar', DTO)
      this.$emit('beforeUpdate', DTO)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    onFileNewChange(e) {
      if (!e) return
      this.urlNew = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-cover {
  height: 30vh;
  max-height: 400px !important;
  min-height: 250px !important;
  position: relative;
}

.img-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.img-cover .cover-title {
  width: 100%;
  position: absolute;
  bottom: 10%;
  color: white !important;
  font-size: 25px;
  margin-left: 10%;
  text-transform: uppercase;
}

.img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-about {
  width: 100%;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
